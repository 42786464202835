import React from "react"
import Modal from "react-bootstrap/Modal"
import Spinner from "react-bootstrap/Spinner"
import styles from "./LoadingModal.module.scss"

const LoadingMessage = () => (
  <div className={styles.loading}>
    <Spinner animation="grow" />
  </div>
)

const LoadingModalView = ({ show }) => {
  return <Modal show={show} backdrop="static" dialogAs={LoadingMessage} />
}

export default LoadingModalView
